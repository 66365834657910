<template>
  <div>
    <div class="handWareBox">
      <div class="title">
        <img :src="imgUrl" alt="" />
        <div class="words">{{ title }}</div>
      </div>
      <div class="content">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "content", "imgUrl"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.handWareBox {
  width: 560px;
  height: 88px;
  display: flex;
  padding: 10px 18px 10px 18px;
  box-sizing: border-box;
  .title {
    width: 64px;
    height: 68px;
    text-align: center;
    img {
      width: 48px;
      height: 48px;
      margin: 0 auto;
    }
    .words {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      width: 64px;
    }
  }
  .content {
    width: 448px;
    color: #666666;
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
    margin-left: 28px;
    padding-right: 20px;
  }
}
</style>
