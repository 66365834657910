<template>
  <div class="contain">
    <div class="title">{{ hTitle }}</div>
    <div class="line"></div>
    <div class="info">{{ hInfo }}</div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    hTitle: {
      type: String,
      default: "",
    },
    hInfo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  background: #252525;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;

  .title {
    font-size: 16px;
    font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
    font-weight: bold;
    color: #ffffff;
  }
  .line {
    width: 40px;
    height: 5px;
    background: #ea9052;
    margin: 20px 0;
  }
  .info {
    font-size: 14px;
    font-family: Noto Sans S Chinese-Light, Noto Sans S Chinese;
    font-weight: 300;
    color: #e2e4ea;
  }
}
</style>
