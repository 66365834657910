<template>
  <div class="contain">
    <img :src="require('@/assets/' + imgUrl)" alt="" />
    <div class="title">{{ title }}</div>
    <div class="descrip">{{ info }}</div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  width: 208px;
  height: 208px;

  border-radius: 3px 3px 3px 3px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  img {
    width: 64px;
    height: 64px;
    margin-bottom: 30px;
  }
  .title {
    font-size: 16px;
    font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }
  .descrip {
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
    font-weight: normal;
    color: #666666;
  }
}
</style>
