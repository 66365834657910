<template>
  <div>
    <div class="intelligent">
      <div class="banner">
        <div class="head">
          <div class="title">智能盘货台</div>
          <div class="line"></div>
          <div class="desc">
            智能设备将为客户提供专业可信的现场协助能力，应用在企业业务场景中；力求“平滑融入，本质改变”的体验
          </div>
        </div>
      </div>
      <div class="funcContent">
        <CommonTitle
          hTitle="功能特色"
          hDescrip="Function Introduction"
        ></CommonTitle>
        <div class="func">
          <FeatureContain
            imgUrl="main/intelligent/data.png"
            title="数据构建"
            info="支持用户完成货品流通数据的构建、货品图片库的构建"
          ></FeatureContain>
          <FeatureContain
            imgUrl="main/intelligent/store.png"
            title="仓储业务"
            info="出入库相关业务，如：货品交割、调库、移库、批发、采购、销售等"
          ></FeatureContain>
          <FeatureContain
            imgUrl="main/intelligent/check.png"
            title="盘点抽检"
            info="辅助仓库的盘点业务支持，通过设备的扫码及RFID识别功能快速批量的完成货品状态检查"
          ></FeatureContain>
          <FeatureContain
            imgUrl="main/intelligent/sale.png"
            title="销售支持"
            info="可以查询货品，给顾客提供标准信息展示；可以采集销售单，开通维保，连接顾客"
          ></FeatureContain>
        </div>
      </div>
      <InfoTitle
        hTitle="智能盘货台"
        hInfo="智能盘货台是前置到各企业及门店，面向敏感货品业务操作的数字化货管设备，作为金箱数云的配套智能设备，实现对货品的立体识别、存证和流转操作"
      ></InfoTitle>
      <div class="hardware">
        <CommonTitle
          hTitle="硬件服务"
          hDescrip="Hardware Services"
        ></CommonTitle>
        <div class="hardwareContent">
          <div class="left">
            <img src="../../assets/goldmatrix/handWare.png" alt="" />
          </div>
          <div class="right">
            <HandWareBox
              class="handWare"
              v-for="item in handWareData"
              :key="item.title"
              :title="item.title"
              :content="item.content"
              :imgUrl="item.imgUrl"
            ></HandWareBox>
          </div>
          <!-- <Box
            :imgUrl="require('@/assets/main/intelligent/1.png')"
            title="拍照录像"
            content="集合多摄像头，多角度采集货品信息；高清摄像头支持操作流程全程录像"
            :height="287"
          ></Box>
          <Box
            :imgUrl="require('@/assets/main/intelligent/2.png')"
            title="标签识别"
            content="高精度的识别摄像头，支持拍照解码（条形码、二维码）；内置标签RFID设备，支持近场交互"
            height="287px"
          ></Box>
          <Box
            :imgUrl="require('@/assets/main/intelligent/3.png')"
            title="货品测重"
            content="挂接高精度天平，称重数据实时准确记录并反馈给操作员"
            height="287px"
          ></Box>
          <Box
            :imgUrl="require('@/assets/main/intelligent/4.png')"
            title="场景记录"
            content="记录工作环境（地理位置，网络环境、操作时间、操作人/交易人）"
            height="287px"
          ></Box> -->
        </div>
      </div>
      <div class="dataService">
        <CommonTitle hTitle="数据服务" hDescrip="Data Service"></CommonTitle>
        <div class="dataServiceMain">
          <div class="dataServiceBox1 dataServiceBox">
            <img src="../../assets/main/intelligent/hover5.png" alt="" />
            <div class="content">
              <div class="title">多维对比</div>
              <div class="info">
                对采集的货品信息，与云仓登记信息做智能初
                判，外观、测重、编号一致及货品状态等
              </div>
            </div>
          </div>
          <div class="dataServiceBox2 dataServiceBox">
            <img src="../../assets/main/intelligent/hover6.png" alt="" />
            <div class="content">
              <div class="title">业务闭环</div>
              <div class="info">
                对货品状态相关业务操作均做闭环确权，前序
                状态检测、关联责任确权，相关通知到位
              </div>
            </div>
          </div>
          <div class="dataServiceBox3 dataServiceBox">
            <img src="../../assets/main/intelligent/hover7.png" alt="" />
            <div class="content">
              <div class="title">数据存证</div>
              <div class="info">
                所有业务操作，按人、场、物、事做关联记录，
                责任到个人，权属到主体。数据容错、云端备份。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@/components/contain/commonTitle";
import FeatureContain from "@/components/contain/featureContain";
import InfoTitle from "@/components/contain/infoTitle";
import Box from "@/components/box";
import HandWareBox from "./handWareBox";
export default {
  components: { CommonTitle, FeatureContain, InfoTitle, Box, HandWareBox },
  data() {
    return {
      handWareData: [
        {
          title: "拍照录像",
          content:
            "集合多摄像头，多角度采集货品信息；高清摄像头支持操作流程全程录像",
          imgUrl: require("@/assets/goldmatrix/handWare-1.png"),
        },
        {
          title: "标签识别",
          content:
            "高精度的识别摄像头，支持拍照解码（条形码、二维码）；内置标签RFID设备，支持近场交互",
          imgUrl: require("@/assets/goldmatrix/handWare-2.png"),
        },
        {
          title: "货品测重",
          content: "挂接高精度天平，称重数据实时准确记录并反馈给操作员",
          imgUrl: require("@/assets/goldmatrix/handWare-3.png"),
        },
        {
          title: "场景记录",
          content:
            "记录工作环境（地理位置，网络环境、操作时间、操作人/交易人）",
          imgUrl: require("@/assets/goldmatrix/handWare-4.png"),
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.intelligent {
  width: 100vw;
  height: 2200px;
  .banner {
    height: 400px;
    background: url("../../assets/main/intelligent/banner.png") no-repeat;
    overflow: hidden;
    background-size: cover;
    .head {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .title {
        width: 160px;
        height: 32px;
        font-size: 32px;
        font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
        font-weight: bold;
        color: #ea9052;
        line-height: 32px;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin: 170px 680px 10px 800px;
      }
      .line {
        width: 360px;
        height: 0;
        border-top: 2px solid rgba(251, 251, 251, 1);
        margin: 40px 0;
        margin-left: 600px;
      }
      .desc {
        width: 408px;
        height: 24px;
        line-height: 28px;
        font-size: 14px;
        font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
        font-weight: normal;
        color: #ffffff;

        margin-left: 560px;
      }
    }
  }
  .funcContent {
    width: 1200px;
    height: 385px;
    margin: 0 auto;
    .func {
      width: 1200px;
      height: 224px;
      display: flex;
      justify-content: space-between;
    }
  }
  .hardware {
    width: 1200px;
    height: 630px;
    margin: 0 auto;
    background: url("../../assets/goldmatrix/feature-back.png") center/cover;
    .hardwareContent {
      width: 100%;
      height: 472px;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .left {
        width: 600px;
        height: 428px;
      }
      .right {
        width: 600px;
        height: 428px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .handWare {
          margin-left: 40px;
        }
      }
    }
  }
  .dataService {
    margin: 0 auto;
    height: 509px;
    background: rgba(245, 245, 245, 1) no-repeat;
    overflow: hidden;
    .dataServiceMain {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .dataServiceBox {
        width: 320px;
        height: 308px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        -webkit-transition: all 0.2s linear; /*渐变效果*/
        transition: all 0.2s linear;
        img {
          border: 1px dashed rgba(245, 245, 245, 1);
          width: 318px;
          height: 179px;
        }
        .content {
          border: 1px solid rgba(226, 228, 234, 1);
          flex: 1;
          .title {
            width: 64px;
            height: 16px;
            line-height: 16px;
            font-size: 14px;
            font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
            font-weight: bold;
            color: #ea9052;
            margin: 20px 128px 11px 127px;
          }
          .info {
            width: 280px;
            height: 38px;
            font-size: 14px;
            line-height: 24px;
            font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
            font-weight: normal;
            color: #333333;
            margin-left: 20px;
          }
        }
      }
      .dataServiceBox:hover {
        z-index: 2; /*设置在顶层显示*/
        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1); /*添加阴影*/
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        -webkit-transform: translate3d(0, -2px, 0); /*向上浮动*/
        transform: translate3d(0, -2px, 0);
        // > img {
        //   content: url("../../assets/main/intelligent/hover5.png");
        // }
        // > .content > .title {
        //   color: rgba(234, 144, 82, 1);
        // }
        // > .content > .info {
        //   color: rgba(51, 51, 51, 1);
        // }
      }
      .dataServiceBox2:hover {
        // > img {
        //   content: url("../../assets/main/intelligent/hover6.png");
        // }
        // > .content > .title {
        //   color: rgba(234, 144, 82, 1);
        // }
        // > .content > .info {
        //   color: rgba(51, 51, 51, 1);
        // }
      }
      .dataServiceBox3:hover {
        // > img {
        //   content: url("../../assets/main/intelligent/hover7.png");
        // }
        // > .content > .title {
        //   color: rgba(234, 144, 82, 1);
        // }
        // > .content > .info {
        //   color: rgba(51, 51, 51, 1);
        // }
      }
    }
  }
}
</style>
